import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "shadow overflow-hidden border-b border-gray-200 sm:rounded-sm bg-gray-200 text-left p-5 mt-5 w-11/12" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "text-md lg:text-xl ml-2" }
const _hoisted_4 = { class: "flex mt-5 items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex flex-col flex-grow" }
const _hoisted_7 = { class: "text-md lg:text-xl ml-2 text-black" }
const _hoisted_8 = { class: "text-md ml-2 text-black" }
const _hoisted_9 = { class: "flex space-x-1" }
const _hoisted_10 = {
  key: 0,
  class: "p-2"
}
const _hoisted_11 = { class: "p-2" }
const _hoisted_12 = { class: "flex flex-col items-end mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeartIcon = _resolveComponent("HeartIcon")!
  const _component_ChatIcon = _resolveComponent("ChatIcon")!
  const _component_question_answer_input = _resolveComponent("question-answer-input")!
  const _component_question_comment = _resolveComponent("question-comment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.answer.post), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          class: "h-12 w-12 rounded-full",
          src: _ctx.answer.user.image ? _ctx.answer.user.image : 'https://avatars.githubusercontent.com/u/21698755?s=88&v=4',
          alt: ""
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.answer.user.name), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.answer.user.role), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (!_ctx.answer.closed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.answer.status), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, _toDisplayString(new Date(_ctx.answer.date).toISOString().slice(0, 10)), 1),
          (_ctx.answer.my_vote)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "p-2 bg-gray-400 rounded-md cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeUpvote()))
              }, [
                _createVNode(_component_HeartIcon, {
                  class: "block h-6 w-6 text-red-800 hover:text-gray-700",
                  "aria-hidden": "true"
                })
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "p-2 bg-gray-400 rounded-md cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.upvote()))
              }, [
                _createVNode(_component_HeartIcon, {
                  class: "block h-6 w-6 text-gray-700 hover:text-red-800",
                  "aria-hidden": "true"
                })
              ])),
          (this.isLoggedIn() && !_ctx.solved)
            ? (_openBlock(), _createElementBlock("span", {
                key: 3,
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (this.toggleComment && this.toggleComment(...args))),
                class: "ml-2 p-2 group relative border border-transparent text-sm font-medium rounded-md bg-gray-400 hover:bg-white items-center flex"
              }, [
                _createVNode(_component_ChatIcon, {
                  class: "h-6 w-6 text-indigo-600",
                  "aria-hidden": "true"
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (this.comment_displayed && this.isLoggedIn() && !_ctx.solved)
        ? (_openBlock(), _createBlock(_component_question_answer_input, {
            key: 0,
            _id: _ctx.answer._id,
            class: "mt-2 mb-5",
            answer: false
          }, null, 8, ["_id"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answer.comments, (comment, index) => {
          return (_openBlock(), _createBlock(_component_question_comment, {
            key: index,
            comment: comment,
            solved: _ctx.solved
          }, null, 8, ["comment", "solved"]))
        }), 128))
      ])
    ])
  ]))
}