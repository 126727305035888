
import { Options, Vue } from "vue-class-component";
import { QuestionDetailType, QuestionDetailDefault } from "../types/question/question-type";
import { HeartIcon, ArrowCircleDownIcon } from "@heroicons/vue/solid";
import QuestionAnswer from "../components/QuestionAnswer.vue";
import QuestionAnswerInput from "../components/QuestionAnswerInput.vue";
import store from "../store";
import { questionDetailHandler } from "../handlers/question/question-handlers";
import Category from "../components/Category.vue";

@Options({
  components: { QuestionAnswer, QuestionAnswerInput, Category, HeartIcon, ArrowCircleDownIcon },
})
export default class About extends Vue {
  isLoggedIn = (): boolean => store.getters.isLoggedIn;

  private question_display: QuestionDetailType = QuestionDetailDefault;

  get isTeacher(): boolean {
    if (!this.isLoggedIn()) return false;
    return this.question_display.teacher_id === store.getters.getAccount._id;
  }

  mounted(): void {
    questionDetailHandler(parseInt(this.$route.params.question_id.toString(), 10))
      .then((questionsDetailRes: QuestionDetailType) => {
        this.question_display = questionsDetailRes;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
