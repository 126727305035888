
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { UserCommentType } from "../types/question/question-type";
import { HeartIcon, ChatIcon } from "@heroicons/vue/solid";
import { upvoteCreateHandler, upvoteDeleteHandler } from "../handlers/answer/upvote-handler";
import QuestionComment from "../components/QuestionComment.vue";
import store from "../store";
import QuestionAnswerInput from "./QuestionAnswerInput.vue";
import { AlertSeverities, showAlert } from "../helpers/alerts";

@Options({
  components: { QuestionAnswerInput, QuestionComment, HeartIcon, ChatIcon },
})
export default class QuestionItem extends Vue {
  @Prop() readonly answer!: UserCommentType;
  @Prop() readonly solved!: boolean;
  isLoggedIn = (): boolean => store.getters.isLoggedIn;

  private comment_displayed = false;

  private toggleComment(): boolean {
    this.comment_displayed = !this.comment_displayed;
    return this.comment_displayed;
  }

  private async upvote(): Promise<void> {
    if (!this.isLoggedIn()) {
      showAlert("Nepřihlášen!", "Nemůžeš hlasovat, když nejsi přihlášen.", AlertSeverities.warn);
      return;
    }

    const response = await upvoteCreateHandler(this.answer._id);
    if (response === undefined) return;
    this.answer.my_vote = true;
  }

  private async removeUpvote(): Promise<void> {
    const response = await upvoteDeleteHandler(this.answer._id);
    if (response === undefined) return;
    this.answer.my_vote = false;
  }
}
