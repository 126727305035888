
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { UserCommentType } from "../types/question/question-type";
import { HeartIcon, ChatIcon } from "@heroicons/vue/solid";
import QuestionAnswerInput from "../components/QuestionAnswerInput.vue";
import store from "../store";

@Options({
  components: { QuestionAnswerInput, HeartIcon, ChatIcon },
})
export default class QuestionComment extends Vue {
  @Prop() readonly comment!: UserCommentType;
  @Prop() readonly solved!: boolean;
  isLoggedIn = (): boolean => store.getters.isLoggedIn;

  private comment_displayed = false;

  private toggleComment(): boolean {
    this.comment_displayed = !this.comment_displayed;
    return this.comment_displayed;
  }
}
