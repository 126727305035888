import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { request } from "@/requests/api-request";
import { AxiosError } from "axios";
import { getHeaderAuth } from "../handlers-helpers";

export async function upvoteCreateHandler(answer_id: number): Promise<void> {
  return await request(undefined, "POST", `upvote/${answer_id}`, undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Tady hlasovat nemůžeš. Možná nejsi přihlášený v kurzu O.o", AlertSeverities.fatal);
          break;
        case 404:
          showAlert("Data se nenašla", "Odpověď, na kterou jsi chtěl zahlasovat, neexistuje.", AlertSeverities.warn);
          break;
        case 409:
          showAlert("Příliš moc hlasů!", "K téhle otázce už jsi hlasoval pro 3 odpovědi.", AlertSeverities.warn);
          break;
      }
    });
}

export async function upvoteDeleteHandler(answer_id: number): Promise<void> {
  return await request(undefined, "DELETE", `upvote/${answer_id}`, undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na téhle odpovědi nemáš hlas.", AlertSeverities.fatal);
          break;
        case 404:
          showAlert("Data se nenašla", "Odpověď, ze které jsi chtěl ubrat hlas, neexistuje.", AlertSeverities.warn);
          break;
      }
    });
}
