
import { Options, Vue } from "vue-class-component";
import { ChatIcon } from "@heroicons/vue/solid";
import { Prop } from "vue-property-decorator";
import { isLoggedIn } from "../handlers/handlers-helpers";
import { questionAnswerHandler, questionCommentHandler } from "../handlers/question/question-handlers";
import { PostForm, PostType } from "../types/question/question-type";

@Options({
  components: { ChatIcon },
})
export default class QuestionAnswerInput extends Vue {
  @Prop() readonly _id!: number;
  @Prop() readonly answer!: number;

  private input = "";

  post: PostType = PostForm;

  async submitAnswer(): Promise<void> {
    this.post.post = this.input;
    const payload = this.post;

    if (isLoggedIn()) {
      if (this.answer) {
        const response = await questionAnswerHandler(payload, this._id);
        if (response === undefined) return;
        this.input = "";
        this.$router.go(0);
      } else {
        const response = await questionCommentHandler(payload, this._id);
        if (response === undefined) return;
        this.input = "";
        this.$router.go(0);
      }
    }
  }
}
