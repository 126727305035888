import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "overflow-hidden w-11/12" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex items-center p-4 h-auto shadow border-b border-gray-400 sm:rounded-sm bg-gray-400 mb-2" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "text-md ml-2 text-black" }
const _hoisted_7 = { class: "text-md ml-2 text-black" }
const _hoisted_8 = { class: "text-md ml-5 flex-grow" }
const _hoisted_9 = { class: "flex space-x-1" }
const _hoisted_10 = { class: "flex flex-col items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatIcon = _resolveComponent("ChatIcon")!
  const _component_question_answer_input = _resolveComponent("question-answer-input")!
  const _component_question_comment = _resolveComponent("question-comment", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "h-8 w-8 rounded-full",
          src: _ctx.comment.user.image ? _ctx.comment.user.image : 'https://avatars.githubusercontent.com/u/21698755?s=88&v=4',
          alt: ""
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.comment.user.name), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.comment.user.role), 1)
        ]),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.comment.post), 1),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", null, _toDisplayString(new Date(_ctx.comment.date).toISOString().slice(0, 10)), 1)
        ]),
        (this.isLoggedIn() && !_ctx.solved)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.toggleComment && this.toggleComment(...args))),
              class: "ml-2 p-2 group relative border border-transparent text-sm font-medium rounded-md bg-gray-400 hover:bg-white items-center flex"
            }, [
              _createVNode(_component_ChatIcon, {
                class: "h-6 w-6 text-indigo-600",
                "aria-hidden": "true"
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (this.comment_displayed && this.isLoggedIn() && !_ctx.solved)
        ? (_openBlock(), _createBlock(_component_question_answer_input, {
            key: 0,
            _id: _ctx.comment._id,
            class: "mt-2 mb-5",
            answer: false
          }, null, 8, ["_id"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comment.comments, (comment, index) => {
          return (_openBlock(), _createBlock(_component_question_comment, {
            key: index,
            comment: comment,
            solved: _ctx.solved
          }, null, 8, ["comment", "solved"]))
        }), 128))
      ])
    ])
  ]))
}